<template>
  <footer>
    <div class="wrapper">
      <!--div class="bottom-img"></div-->
      <div class="nav-bottom">
        <div class="footerInfo">
          <div class="bottom">
            <img src="../assets/img/site_logo.png">
            <span class="copyright">Copyright 2024 Tiara. All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState([
      'siteOption',
      'commonCodeByOrder'
    ])
  }
}
</script>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped>
.footerInfo ul li{cursor: pointer;}
</style>
